import React, { useState, useRef, useEffect } from 'react';
import { LandingLogo } from '../../../Assets/Svgs';

interface PermissionModalProps {
    modalOpen?: boolean;
    handleClose?: any
}

const PermissionFlowModal: React.FC<PermissionModalProps> = ({ modalOpen, handleClose }: PermissionModalProps) => {
    const [messages, setMessages] = useState([
        { text: "Let's check your audio and video to make sure you are ready for the call. Shall we get started?", fromUser: false }
    ]);
    const [showPlayButton, setShowPlayButton] = useState(false);
    const [showYesNoButtons, setShowYesNoButtons] = useState(false);
    const [showOkayButton, setShowOkayButton] = useState(false);
    const [showSecondOkayButton, setShowSecondOkayButton] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showFinalYesNoButtons, setShowFinalYesNoButtons] = useState(false);
    const [showByeButton, setShowByeButton] = useState(false);

    const videoRef = useRef<HTMLVideoElement>(null);

    const handleUserMessageSend = (userText: string, botResponse: string) => {
        setMessages(prevMessages => [
            ...prevMessages,
            { text: userText, fromUser: true }
        ]);

        setTimeout(() => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: botResponse, fromUser: false }
            ]);
        }, 500);
    };

    const handleButtonClick = (userText: string) => {
        switch (userText) {
            case "Yes, Please":
                handleUserMessageSend(userText, "First, let's test your speakers (or headphones). Click Play!");
                setShowPlayButton(true);
                break;
            case "No, Thanks":
                handleUserMessageSend(userText, "Okay, we can skip the check. If you change your mind, let me know.");
                break;
            default:
                handleUserMessageSend(userText, "I'm not sure how to respond to that.");
                break;
        }
    };

    const handlePlayButtonClick = () => {
        const audio = new Audio('https://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/start.ogg');
        audio.play();

        audio.onended = () => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: "Do you hear the sound that's playing?", fromUser: false }
            ]);
            setShowPlayButton(false);
            setShowYesNoButtons(true);
        };
    };

    const handleYesNoAfterAudio = (userText: string) => {
        handleUserMessageSend(userText, `Great! That means you will hear the other person.`);
        setTimeout(() => {
            setMessages(prevMessages => [
                ...prevMessages,
                { text: "Okay, now let's check your microphone and camera.", fromUser: false }
            ]);
        }, 1000);
        setShowYesNoButtons(false);
        setShowOkayButton(true);
    };

    const handleOkayButtonClick = () => {
        if (!showSecondOkayButton) {
            handleUserMessageSend("Okay!", "I may need your permission to use your microphone and camera. If prompted, click Allow.");
            setShowSecondOkayButton(true);
        } else {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(stream => {
                    handleUserMessageSend("Okay!", "Okay, it looks like I have access to your camera and microphone.");
                    setTimeout(() => {
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { text: "Say something into your microphone.", fromUser: false }
                        ]);

                        setTimeout(() => {
                            handleUserMessageSend("Sound detected!", "Excellent! I hear you loud and clear.");
                            setTimeout(() => {
                                setMessages(prevMessages => [
                                    ...prevMessages,
                                    { text: "Okay, time for the camera. Do you see a video of yourself?", fromUser: false }
                                ]);
                                setShowVideo(true);

                                if (videoRef.current) {
                                    videoRef.current.srcObject = stream;
                                    videoRef.current.play();
                                }

                                setShowFinalYesNoButtons(true); // Show Yes/No buttons for the final camera check

                            }, 1000);
                        }, 2000);
                    }, 1000);
                })
                .catch(err => {
                    handleUserMessageSend("Okay!", "It looks like I don't have access to your camera and microphone. Please check your permissions.");
                })
                .finally(() => {
                    setShowSecondOkayButton(false);
                });
        }
        setShowOkayButton(false);
    };

    const handleFinalYesNoClick = (userText: string) => {
        handleUserMessageSend(userText, "All done. Looks like your audio and video are working properly.");
        setShowFinalYesNoButtons(false);
        setShowByeButton(true); // Show the Bye button
    };

    const chatRef = useRef<any>(null);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <>
            {modalOpen && (
                <div>
                    <div className="login-backdrop" />
                    <div
                        className={`modal fade ${modalOpen ? "show" : ""}`}
                        style={{ display: modalOpen ? "block" : "none" }}
                    >
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-md">
                            <div className="modal-content p-0 shadow-none border-0 overflow-visible">
                                <div className="modal-body p-0">
                                    <div className='permission-modal'>
                                        <div className='permission-content'>
                                            <div className='d-flex justify-content-center modal-logo'>
                                                <img src={LandingLogo} alt="" />
                                            </div>
                                            <div className='permission-chat' ref={chatRef}>
                                                {messages.map((message, index) => (
                                                    <div
                                                        key={index}
                                                        className={`message ${message.fromUser ? "user-message" : "bot-message"}`}
                                                    >
                                                        {message.text}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='permission-btns '>
                                                {showVideo && (
                                                    <div className='video-preview d-flex justify-content-center'>
                                                        <video ref={videoRef} autoPlay playsInline muted />
                                                    </div>
                                                )}
                                                <div className='d-flex justify-content-between'>

                                                    {showPlayButton ? (
                                                        <button className='save-theme-btn mx-auto' onClick={handlePlayButtonClick}>Play</button>
                                                    ) : showYesNoButtons ? (
                                                        <>
                                                            <button className='save-theme-btn' onClick={() => handleYesNoAfterAudio("Yes")}>Yes</button>
                                                            <button className='save-theme-btn' onClick={() => handleYesNoAfterAudio("No")}>No</button>
                                                        </>
                                                    ) : showOkayButton || showSecondOkayButton ? (
                                                        <button className='save-theme-btn mx-auto' onClick={handleOkayButtonClick}>Okay!</button>
                                                    ) : showFinalYesNoButtons ? (
                                                        <>
                                                            <button className='save-theme-btn' onClick={() => handleFinalYesNoClick("Yes")}>Yes</button>
                                                            <button className='save-theme-btn' onClick={() => handleFinalYesNoClick("No")}>No</button>
                                                        </>
                                                    ) : showByeButton ? (
                                                        <button className='save-theme-btn mx-auto' onClick={handleClose}>Bye</button>
                                                    ) : (
                                                        <>
                                                            <button className='save-theme-btn' onClick={() => handleButtonClick("Yes, Please")}>Yes, Please</button>
                                                            <button className='save-theme-btn' onClick={() => handleButtonClick("No, Thanks")}>No, Thanks</button>
                                                        </>
                                                    )}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PermissionFlowModal;
