import React, { useState } from 'react'
//css
import '../../Pages/Patient/PatientLoginFlow/WaitingScreen.scss'
import { LandingLogo, LoginPopupBG, Padlock } from '../../Assets/Svgs'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ResetPasswordProfile } from '../../Store/Thunk/ResetPassword/resetPasswordThunk';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface NewForgotPasswordProps {
    type?: string;
}

const NewForgotPassword = ({ type }: NewForgotPasswordProps) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [showModal, setShowModal] = useState<boolean>(true);
    const [loader, setLoader] = useState<boolean>(false);

    const handlePassword = async (email: string) => {
        if (type === 'demo') {
            navigate('/forgot-password', {
                state: "demo-center"
            })
        } else {
            setLoader(true);
            toast.loading("Please wait...");
            let response: any = await dispatch(ResetPasswordProfile({ email }));
            if (response?.payload?.message === "password reset successful.") {
                toast.success("Email sent successfully");
            } else {
                toast.error("Something went wrong. Please try again later.");
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent, submitFunction: () => void) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submission
            submitFunction(); // Call the submit function
        }
    };
    return (
        <div>
            <div className="background-container">
                <img src={LoginPopupBG} alt="" />
            </div>
            <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }}>
                <div className={`modal-dialog modal-dialog-scrollable modal-dialog-centered justify-content-center modal-lg`}>
                    <div className="modal-content bg-transparent p-0 shadow-none border-0 overflow-visible" style={{ maxWidth: "628px", backgroundColor: '#ffffff66' }}>
                        <div className="modal-body p-0">
                            <div className="waiting-screen new-login-popup" style={{ background: 'transparent' }}>
                                <div className="waiting-login">
                                    <h1 className="waiting-header justify-content-center align-items-center flex-row gap-4">Create a new password! <img src={Padlock} alt="" /></h1>
                                    <p className="desc">Let’s setup a new password</p>
                                    <div className="signin-div d-flex w-75 align-items-center flex-column gap-4">
                                        <div className="waiting-signin w-100 d-flex flex-column gap-4">
                                            <Formik
                                                initialValues={{ email: '' }}
                                                validationSchema={Yup.object({
                                                    email: Yup.string().email('Invalid email address').required('Email Required'),
                                                })}
                                                onSubmit={({ email }) => handlePassword(email)}
                                            >
                                                {({ handleSubmit, errors, touched }) => (
                                                    <Form className="login-form d-flex flex-column" onSubmit={handleSubmit}>
                                                        <div>
                                                            <Field
                                                                name="email"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter your Email"
                                                                autoComplete='new-email'
                                                                onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
                                                            />
                                                            <ErrorMessage component='div' name='email' className='text-danger' />
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="yes-theme-btn border-0"
                                                            style={{
                                                                padding: "16px 24px",
                                                                borderRadius: "6px",
                                                            }}
                                                            disabled={loader}
                                                        >
                                                            Continue
                                                        </button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                        <div className={`text-center login-logo`}>
                                            <img src={LandingLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewForgotPassword