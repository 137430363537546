
import { ApiUrl } from '../../../Network/ApiUrl';
import { post } from '../../../Network/ApiService';

// redux
import { RootState } from '../../index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

interface ResetUserPasswordDataArgs {
    email: string;
}

// Define the return type for the Thunk action
interface ResetPasswordMessages {
    messages: any; // Adjust the type of userData to match your data structure
}

// Create the Thunk action
export const ResetPasswordProfile = createAsyncThunk<ResetPasswordMessages, ResetUserPasswordDataArgs, { state: RootState }>(
    'profile/resetPassword',
    async ({ email }, { dispatch }) => {
        try {
            const ApiEndPoint = `${ApiUrl.user_profile.reset_password}?email=${email}`;
            const response = await post(ApiEndPoint);
            toast.dismiss()
            if (response.status === 200) {
                return response.data; 
            } else {
                toast.error("Error in Sending Mail to the User")
                // throw new Error('Failed to Send Email');
            }
        } catch (error) {
            toast.error("Error in Sending Mail to the User")
            throw error;
        }
    }
);
