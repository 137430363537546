import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import 'cropperjs/dist/cropper.css';
import { CloseModelIcon, ImageUploadSquare } from "../../../../Assets/Svgs";

interface UploadCropImageModal {
    showModal: boolean;
    modalTitle: string;
    closeModal: any;
    selectedImage: string;
    confirm: any;
    handleChangeImage: (file: any) => void;
    uploadRef: any;
    resultRef: any;
    imgResultRef: any;
    croppedRef: any;
    saveRef: any;
    handlePreview: any;
    cropper: any,
    selectImage: any;
    hadleSave: () => void;
    loader: boolean;
    patientUserData?: any;
    selectedPrimaryColor?: any
}
const UploadCropImageModal = ({ showModal, modalTitle, closeModal, selectedImage, confirm, handleChangeImage, selectImage, selectedPrimaryColor,
    uploadRef, resultRef, imgResultRef, croppedRef, saveRef, handlePreview, cropper, hadleSave, loader, patientUserData
}: UploadCropImageModal) => {
    const imgElement = resultRef?.current?.querySelector('#image');

    return (
        <div>
            {showModal && (
                <div className="modal-backdrop" onClick={closeModal} />
            )}
            <div
                className={`modal fade ${showModal ? "show" : ""} share-record-modal image-crop-modal`}
                style={{ display: showModal ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body pt-0">
                            <div className="modal-header">
                                <span style={{ color: `${selectedPrimaryColor ? selectedPrimaryColor : "#516CD3"}`, fontSize: '24px', fontWeight: '700' }}>{modalTitle}</span>
                                <span className="" data-bs-dismiss="modal">
                                    {/* <FontAwesomeIcon icon={faClose} onClick={closeModal} size="xl"/> */}
                                    <img src={CloseModelIcon} alt="Close" className='close-model-icon' style={{ width: "20px" }} onClick={closeModal} />
                                </span>
                            </div>
                            <main className="page">
                                <div className="d-flex overflow-x-hidden ">
                                    <div className="box-2 d-flex w-100 justify-content-center ">
                                        {selectedImage ?
                                            <div className="result" ref={resultRef}> </div>
                                            :
                                            <img src={ImageUploadSquare} alt="Default Logo" style={{ height: '100%' }} />
                                        }
                                    </div>
                                    <div className={`box-2 img-result`} ref={imgResultRef}>
                                        <img ref={croppedRef} className={`cropped`} src={''} alt="" />
                                    </div>

                                </div>
                                <div className="d-flex justify-content-center gap-2">
                                    <div className="position-relative d-flex justify-content-center ">
                                        <input className="input-crop-image d-none" type="file" ref={uploadRef} accept=".jpg, .jpeg, .png, .pdf" onChange={handleChangeImage} />
                                        <button onClick={selectImage} type="button" className="save-theme-btn">Select Image</button>
                                    </div>
                                    <button className="save-theme-btn" ref={saveRef} onClick={handlePreview}>
                                        Preview
                                    </button>

                                </div>
                            </main>
                            <div className="share-record-btns admin-action-modalbtn justify-content-between ">
                                <button
                                    className="save-theme-btn"
                                    data-bs-dismiss="modal"
                                    onClick={closeModal}
                                    style={{ padding: "11.5px 40px" }}
                                >
                                    Cancel
                                </button>
                                <button className="rounded-save-theme-btn" data-bs-dismiss="modal" onClick={hadleSave} disabled={imgElement === null || loader}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default UploadCropImageModal;
