import React, { useRef, useState } from 'react'
import images from '../../../Assets/Images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import OnBoarding from '../../ClinicOnboarding/OnBoarding';


const Monthly = ({ setIsOnboarding }: any) => {
    const [activeIndex, setActiveIndex] = useState(2);
    const [displayFeatures, setDisplayFeatures] = useState(false)
    const [isOpenOnboarding, setIsOpenOnboarding] = useState(false)
    const [planName, setPlanName] = useState('')
    const plansSectionRef = useRef<HTMLDivElement>(null);


    const handleClick = (planDetails: string) => {
        // navigate('/pricing/onboarding')
        setIsOpenOnboarding(true)
        setPlanName(planDetails)
    }

    const handleClose = () => {
        setIsOpenOnboarding(false)
    }

    const handleMouseEnter = (index: any) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(2); // Set the default active index when leaving
    };

    const displayAllFeatures = () => {
        setDisplayFeatures(!displayFeatures)
        if (displayFeatures && plansSectionRef.current) {
            plansSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <>
            <section className='container'>
                <div id='pricing' className='row '>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-md-3 plans-details'>
                        <div onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave} className={`essential w-100 secondary d-flex justify-content-between flex-column ${activeIndex === 0 ? 'active-plan ' : ''}`}>
                            <div className="top">
                                <h1 className='header'>INDIVIDUAL PROVIDER</h1>
                                <div className='price-details'>
                                    <h1 className='price'>$35<span>/month</span></h1>
                                    <p>Unlimited use</p>
                                </div >
                                <div className='details'>
                                    <p className='heading mb-2 ms-0 invisible'>Clinic Platform +</p>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'>Secure Encrypted Appointment Links</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'>Access Control & Authentication</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'>Compliant Provider Registration</p>

                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'>Transmission & Session Encryption</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'> Teleconsent</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'>Customized Branding</p>
                                    </div>
                                    {/* <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'>Encounter Summaries</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt='icon' />
                                        <p className='d-inline'>Teleconsent</p>
                                    </div> */}
                                </div>
                            </div>

                            {activeIndex === 0 ?
                                <div className='contact' onClick={() => handleClick("individual")}>
                                    Get Compliant Now!
                                </div> :
                                <div className='get-btn'>
                                    Get Compliant Now!
                                </div>}
                        </div>

                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-md-3 plans-details'>
                        <div onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave} className={` essential w-100 d-flex justify-content-between flex-column ${activeIndex === 1 ? 'active-plan ' : ''}`}>
                            <div className="top">
                                <h1 className='header'>CLINIC PLATFORM</h1>
                                <div className='price-details'>
                                    <h1 className='price'>$50<span>/provider/month</span></h1>
                                    <p>Unlimited use</p>
                                </div>
                                <div className='details'>
                                    <p className='heading mb-2 ms-0'>Individual Provider +</p>
                                    {/* <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Practice Website Integration</p>
                                    </div> */}
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Admin Center</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Unlimited Admins</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Patient & Provider Profiles</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Provider Room Directory</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Org & Provider Encounters</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Org & Provider Analytics</p>
                                    </div>
                                </div>
                            </div>
                            {activeIndex === 1 ?
                                <div className='contact' onClick={() => handleClick("clinic")}>
                                    Get Compliant Now!
                                </div> :
                                <div className='get-btn'>
                                    Get Compliant Now!
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 mt-md-3 plans-details' ref={plansSectionRef}>
                        <div onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={handleMouseLeave} className={`essential w-100 d-flex justify-content-between flex-column ${activeIndex === 2 ? 'active-plan' : ''}`}
                        >
                            <div className="top">
                                <h1 className='header'>TAILORED TO YOUR NEEDS</h1>
                                <div className='price-details'>
                                    <h1 className='price'>Add-On Features</h1>
                                    <p className='invisible'>Unlimited use</p>
                                </div>
                                <div className='details'>
                                    <p className='heading mb-2 ms-0'>Clinic Platform +</p>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>HIPAA Compliant Payments</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>2-way EHR Integration </p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Flexcare Provider Scheduling</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>my Appointments for Patients</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Customized Digital Forms </p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>360 Medical Records </p>
                                    </div>

                                    {
                                        displayFeatures &&
                                        <>
                                            <div className='d-flex'>
                                                <img src={images.Icon} alt="icon" />
                                                <p className='d-inline'>Auto Transcribe & AI Charting </p>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={images.Icon} alt="icon" />
                                                <p className='d-inline'>Claims & Billing</p>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={images.Icon} alt="icon" />
                                                <p className='d-inline'>Remote Patient Monitoring</p>
                                            </div>
                                            <div className='d-flex'>
                                                <img src={images.Icon} alt="icon" />
                                                <p className='d-inline'>ePrescribe </p>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='add-on-plan d-flex align-items-center justify-content-between mt-3' onClick={displayAllFeatures}>
                                    {displayFeatures ? "See Less" : "See all Add-On Features"}
                                    <span>
                                        <FontAwesomeIcon icon={displayFeatures ? faCaretUp : faCaretDown} color='#516cd3' />
                                    </span>
                                </div>
                            </div>
                            {activeIndex === 2 ?
                                <div className='contact'>
                                    Contact us
                                </div> :
                                <div className='get-btn'>
                                    Contact us
                                </div>
                            }
                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 col-sm-12 mt-md-3 plans-details">
                        <div onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={handleMouseLeave} className={`essential w-100 secondary d-flex justify-content-between flex-column ${activeIndex === 3 ? 'active-plan' : ''}`}>
                            <div className="top">
                                <h1 className='header'>PLATFORM UPGRADES</h1>
                                <div className='price-details'>
                                    <h1 className='price' style={{ marginBottom: '60px' }}>Quoted</h1>
                                </div>
                                <div className='details'>
                                    <p className='heading mb-2'>Tailored to your needs</p>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Payment Processing </p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>2-Way EHR Integration</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Claims & Billing</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Auto-Transcription</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>AI-Auto Charting with ICD-10, SNOMED & RX Norm NLP Integration</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>Remote Patient Monitoring with 4,000 pre-integrated devices</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src={images.Icon} alt="icon" />
                                        <p className='d-inline'>e-Prescribe</p>
                                    </div>
                                </div>
                            </div>
                            {activeIndex === 3 ?
                                <div className='contact'>
                                    Get Quote
                                </div> :
                                <div className='get-btn'>
                                    Get Quote
                                </div>}
                        </div>
                    </div> */}
                </div>
                {
                    isOpenOnboarding && <OnBoarding planName={planName} handleClose={handleClose} modalOpen={isOpenOnboarding}/>
                }
            </section>
        </>
    )
}

export default Monthly